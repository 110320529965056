export const GrayArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 8V16H7V8H0L8 0L16 8H9Z" fill="#4B4B4B" />
  </svg>
);

export const WhiteArrow = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.38126 7.18143L3.04377 7.18143L3.04377 6.09706L7.38126 6.09706L7.38126 2.30176L11.7188 6.63925L7.38126 10.9767L7.38126 7.18143Z"
      fill="white"
    />
  </svg>
);
